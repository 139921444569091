import { BaseModel } from '../base-model.class';

export default class NewsletterSubscription extends BaseModel {
  name = '';
  firstName = '';
  lastName = '';
  email = '';
  recaptchaToken = '';
  language = '';
}
