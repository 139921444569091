
import { Component, Mixins } from 'vue-property-decorator';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import VueRecaptcha from 'vue-recaptcha';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import FooterContactInfo from '../components-smart/FooterContactInfo.vue';
import ContactDirections from '../components/ContactDirections.vue';
import NewsletterSubscription from '../models/newsletter-subscription/newsletter-subscription.class';
import { ValidationObserver } from 'vee-validate';
import env from '../../env.config';
import FormInput from '../components/FormInput.vue';
import { namespace } from 'vuex-class';

const newslettersModule = namespace('newsletterSubscriptions');

@Component({
  components: {
    ContactDirections,
    ValidationObserver,
    FormInput,
    VueRecaptcha,
    FooterContactInfo,
  },
})
export default class NewsletterRegistration extends Mixins(
  UsePageHeaderMixin,
  UseCurrentLocaleMixin,
) {
  @newslettersModule.Action('CREATE')
  submitNewsletterForm!: (newsletter: NewsletterSubscription) => {};

  newsletterForm: NewsletterSubscription = new NewsletterSubscription();
  successMessageSeconds = 0;
  submitBtnEnabled = false;
  isLoading = false;
  errorTranslationKey = '';

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
    recaptcha: InstanceType<typeof VueRecaptcha>;
  };

  get headerHtml() {
    return `<h2 class="text-white">${this.$t('newsletter.title')}</h2>`;
  }

  get googleRecaptchaSiteKey() {
    return env.RECAPTCHA_SITE_KEY;
  }

  async onReCaptchaVerify(token: string) {
    if (token) {
      this.newsletterForm.recaptchaToken = token;
      this.submitBtnEnabled = true;
    }
  }

  onReCaptchaExpired() {
    this.submitBtnEnabled = false;
  }
  async onSubmitForm() {
    this.isLoading = true;
    this.errorTranslationKey = '';
    this.newsletterForm.language = this.localeCode;
    this.newsletterForm.name = this.newsletterForm.firstName + this.newsletterForm.lastName;
    const contactResponse = await this.submitNewsletterForm(this.newsletterForm);

    if (typeof contactResponse === 'string') {
      this.errorTranslationKey = contactResponse;
      this.resetNewsletterForm();
    } else {
      this.resetNewsletterForm();
      this.successMessageSeconds = 8;
      window.scrollTo(0, 0);
    }

    this.isLoading = false;
  }

  resetNewsletterForm() {
    this.newsletterForm = new NewsletterSubscription();
    this.$refs.observer.reset();
    this.$refs.recaptcha.reset();
    this.submitBtnEnabled = false;
  }
}
